import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:'',
    userInfo:null,
    config:null,
    showNav:true,
    menuActive:0,
    showLogin:true
  },
  mutations: {
    setToken:function(state,info){
      console.log("执行设置mutations setToken",info);
      state.token=info.token;
    //  state.userInfo=info;
    //  window.localStorage['userInfo']=window.JSON.stringify(info);
      window.localStorage['token']=info.token;
    },
    clear:function(state){
      state.token=null;
      state.userInfo=null;
      window.localStorage.clear();
    },
    setConfig:function(state,config){
      state.config=config;
    },
    setUserInfo:function(state,info){
      state.userInfo=info;
    },
    setShowNav:function(state,value){
      state.showNav=value;
    },
    setMenuActive:function(state,value){
      console.log("菜单变更了",value);
      state.menuActive=value;
    },
    setLoginNav:function(state,value){
      console.log("login status",value);
      state.showLogin=value;
    }
  },
  actions: {
    setToken(context,info){
      console.log("执行actions setToken",info);
      context.commit('setToken',info)
    },
    clearToken(context){
      context.commit('clear',info)
    },
    setConfig(context,config){
      console.log("设置全局参数",config);
      context.commit('setConfig',config)
    },
    setUserInfo(context,info){
      console.log("设置用户数据",info);
      context.commit('setUserInfo',info)
    },
    setShowNav(context,value){
      console.log("设置nav",value);
      context.commit('setShowNav',value)
    },
    setMenuActive(context,value){
      context.commit('setMenuActive',value)
    },
    setLoginActive(context,value){
      context.commit('setLoginNav',value)
    }
  },
  modules: {},
  getters:{
    userInfo:state=>{
      if(!state.userInfo){
         var userInfo=window.localStorage["userInfo"];
         if(userInfo){
          state.userInfo=window.JSON.parse(userInfo);
          return state.userInfo;
         }else{
           return null;
         }
      }else{
        return state.userInfo
      }
       
    },
    token:state=>{
      if(!state.token){
        var token=window.localStorage["token"];
        if(!token){
          return null;
        }else{
          state.token=token;
          return state.token;
        }

      }else{
        return state.token
      }
       
    },
    config:state=>{
      return state.config;
    },
    userInfo:state=>{
      return state.userInfo
    },
    showNav:state=>{
      return state.showNav
    },
    menuActive:state=>{
      return state.menuActive
    },
    loginActive:state=>{
      return state.showLogin
    }
  }
});
