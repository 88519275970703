<template>
  <div class="home">
      <div class="headerPart" >
        <div class="info">
            Home

        </div>
        <div class="swipeWrap">
          <div style="display:block" class="swipeWrap1" :style="{width:screeWidth+'px'}">
           <van-swipe class="my-swipe" style="height:5.24rem;" :autoplay="3000" indicator-color="white">
            
            <van-swipe-item v-for="(item,i) in bannerList" :key="i" >
               
               <img :src="item" style="width:100%;"/>
            </van-swipe-item>
             
          </van-swipe> 
          </div>
        </div>
      </div>
      <div>
      <div class="token_list" style="overflow-x: scroll">
           <div class="citem" v-for="(item,index) in ticks" :key="index">
               <div><img :src="item.icon" style="width:28px"></div>
               <div class="token_name"><span>{{item.token1}}</span>/<span>{{item.token2}}</span></div>
               <div class="token_price">{{item.last}}</div>
           </div>
          
      </div>
      </div>

      <div style="padding-bottom: 10px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 15px;">

<van-grid :column-num="4" :border="false">
  <van-grid-item v-for="(value,index) in menus" @click="gridClick(value)" :key="index" :icon="value.icon" :text="value.label" />
</van-grid>

      </div>
      <div>
        <div style="display:flex;justify-content:space-between;font-size:0.4rem;padding: 0rem 0.5rem;margin-bottom:0.3rem">
          <div>Currency</div>
          <div>Latest price</div>
        </div>
        <van-cell v-for="(item,index) in ticks" :key="index" >
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title" style="font-weight:bold;margin-left:5px;">{{item.token1}}/</span>
             <span>{{item.token2}}</span>
          </template>
          <template #default>
            <span style="font-weight:bold;color:#000000">{{item.last}}</span> 
          </template>
          <template #icon>
             <img :src="item.icon" style="width:28px"/>
          </template>
          
        </van-cell>
 
       
      </div>
  </div>
</template>

<script>
 import {fetch} from '@/util/api'
 import {getTicks,getUserInfo,getBackData} from '@/util/api'
 import ban1 from '@/assets/cryimg/202311141.png';
 import ban2 from '@/assets/cryimg/202311142.png';
 import ban3 from '@/assets/cryimg/202311143.png';
 import ban4 from '@/assets/cryimg/202311144.png';
 import BTCLOGO from '@/assets/cryimg/BTC-USDT.png';
 import ETHLOGO from '@/assets/cryimg/ETH-USDT.png';
 import DOGELOGO from '@/assets/cryimg/DOGE-USDT.png';
 import EOSLOGO from '@/assets/cryimg/EOS-USDT.png';
 import LTCLOGO from '@/assets/cryimg/LTC-USDT.png';
 import BCHLOGO from '@/assets/cryimg/BCH-USDT.png';
 import XRPLOGO from '@/assets/cryimg/XRP-USDT.png';
 import ETCLOGO from '@/assets/cryimg/ETC-USDT.png';
 import BSVLOGO from '@/assets/cryimg/BSV-USDT.png';
 import ADALOGO from '@/assets/cryimg/ADA-USDT.png';
 import FILLOGO from '@/assets/cryimg/FIL-USDT.png';
 import TRXLOGO from '@/assets/cryimg/TRX-USDT.png';
 import UNILOGO from '@/assets/cryimg/UNI-USDT.png';
 import LINKLOGO from '@/assets/cryimg/LINK-USDT.png';
 import SOLLOGO from '@/assets/cryimg/SOL-USDT.png';
 import AAVELOGO from '@/assets/cryimg/AAVE-USDT.png';

 import WALLETICON from '@/assets/cryimg/nav_icon_5.png';
 import RECHARGEICON from '@/assets/cryimg/nav_icon_1.png';
 import WITHDRAWICON from '@/assets/cryimg/nav_icon_2.png';
 import TRANFERICON from '@/assets/cryimg/nav_icon_3.png';
 import HELPICON from '@/assets/cryimg/nav_icon_6.png';
 import INVESTICON from '@/assets/cryimg/nav_icon_7.png';
 import VIPICON from '@/assets/cryimg/nav_icon_4.png';
 import CUSTOMERICON from '@/assets/cryimg/nav_icon_8.png';
export default {
  name: "Home",
  data(){
     return{
       screeWidth:document.body.clientWidth-30,
       tactive:1,
       bannerList:[ban1,ban2,ban3,ban4],
       basepath:process.env.VUE_APP_BASE_API,
       memberList:[],
       merchant:[],
       businessList:[],
       initpairs:["BTC-USDT", "ETH-USDT", "DOGE-USDT","EOS-USDT","LTC-USDT","BCH-USDT","XRP-USDT","ETC-USDT","BSV-USDT","ADA-USDT","FIL-USDT","TRX-USDT","UNI-USDT","LINK-USDT","SOL-USDT","AAVE-USDT"],
       pair_icons:{
        "BTC-USDT":BTCLOGO,
        "ETH-USDT":ETHLOGO,
        "DOGE-USDT":DOGELOGO,
        "EOS-USDT":EOSLOGO,
        "LTC-USDT":LTCLOGO,
        "BCH-USDT":BCHLOGO,
        "XRP-USDT":XRPLOGO,
        "ETC-USDT":ETCLOGO,
        "BSV-USDT":BSVLOGO,
        "ADA-USDT":ADALOGO,
        "FIL-USDT":FILLOGO,
        "TRX-USDT":TRXLOGO,
        "UNI-USDT":UNILOGO,
        "LINK-USDT":LINKLOGO,
        "SOL-USDT":SOLLOGO,
        "AAVE-USDT":AAVELOGO
       },
       menus:[
        {label:'Wallet',icon:WALLETICON},
        {label:'Recharge',icon:RECHARGEICON},
        {label:'Withdraw',icon:WITHDRAWICON},
        {label:'Transfer',icon:TRANFERICON},
        {label:'Help center',icon:HELPICON},
        {label:'Invesetment',icon:INVESTICON},
        {label:'VIP',icon:VIPICON},
        {label:'Contact customer service',icon:CUSTOMERICON},
       ],
       swipeInit:false,
       swipe2Init:false,
       ticks:[]
     }
  },
   created(){
    this.screeWidth=document.body.clientWidth-30;
     this.$store.dispatch("setShowNav",true);
     this.init();
     if(this.$route.query.index){
        this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
     }
     
  },
  methods:{
    gridClick:function(item){
          console.log("点击菜单",item);
          if(item.label=="Recharge"){
            this.$router.push({
                  path: "/Recharge" 
            });
          }
          if(item.label=="Withdraw"){
            this.gowithdraw();
          }
          if(item.label=="VIP"){
             this.$router.push({
              path: "/vip" 
          });
          
          }
           if(item.label=="Transfer"){
             this.$router.push({
              path: "/tranfer" 
          });
          
          }
          if(item.label=="Invesetment"){
             this.$router.push({
              path: "/investment" 
          });
          
          }
          if(item.label=="Wallet"){
             this.$router.push({
              path: "/wallet" 
             });
          
          }
          if(item.label=="Help center"){
            this.$router.push({
                          path: "/help" 
                        });
          }
    },
    copyText:function(){
      // var text=this.$store.state.config.info.setting.writing;
      //           var input = document.createElement("textarea");   // 直接构建input
      //           input.value = text;  // 设置内容
      //           document.body.appendChild(input);    // 添加临时实例
      //           input.select();   // 选择实例内容
      //           document.execCommand("Copy");   // 执行复制
      //           document.body.removeChild(input); // 删除临时实例
      //           this.$toast(" copy success");
       this.$router.push({
                  path: "/invite" 
            });
    },
    goNotice:function(){
    this.$router.push({
                  path: "/notice" 
            });
    },
    gowithdraw:function(){
this.$router.push({
              path: "/withdraw" 
        });
    },
    goTeam:function(){

        this.$router.push({
              path: "/team" 
        });
    },
    goRecharge:function(){
        this.$router.push({
              path: "/recharge" 
        });
    },
    changeIndex:function(index){
      console.log("index111111");
       if(!this.swipeInit){
          this.swipeInit=true;
              var swipeWrap=document.querySelector("#swipeList1>.van-swipe__track--vertical");
              var swipeTemplate=document.querySelector("#swipeTemplate");
              for(var i=0;i<10;i++){
                var temp=swipeTemplate.children[i];
                console.log(temp);
               // temp.id="asd"+i;
                swipeWrap.appendChild(temp);
              }
       }
    },
    changeIndex2:function(index){
      console.log("index2222");
       if(!this.swipe2Init){
          this.swipe2Init=true;
              var swipeWrap=document.querySelector("#swipeList2>.van-swipe__track--vertical");
              var swipeTemplate=document.querySelector("#swipe2Template");
              for(var i=0;i<10;i++){
                var temp=swipeTemplate.children[i];
                console.log(temp);
               // temp.id="asd"+i;
                swipeWrap.appendChild(temp);
              }
       }
    },
    goBusinessHall:function(){
      this.$router.push({
              path: "/businesshall" 
        });
    },
    goTaskHall:function(){
      this.$router.push({
              path: "/taskhall",
              query:{
                menu:1
              } 
        });
    },
    changeTb:function(index){
       this.tactive=index;
    },
    getTicks(ticks){
      var tickdata=ticks;
      debugger;
      for(var i in this.initpairs){
        var pair=this.initpairs[i];
         var data=tickdata[pair];
         var icon=this.pair_icons[pair];
         data["icon"]=icon;
         var instId=data["instId"];
         var tokenArr=instId.split("-");
         data["token1"]=tokenArr[0];
         data["token2"]=tokenArr[1];
         this.ticks.push(data);
      }
      console.log("aa",this.ticks);
    },
    init:async function(){
       this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay:true
            });
            var ticks=await getTicks();
            console.log(ticks);
             this.getTicks(ticks);
      this.$toast.clear();
      var user=await getUserInfo();
      getBackData();
      if(user!=null&&user){
        this.$store.dispatch("setShowNav",true);
      }
    }
  },
  activated(){
     this.$store.dispatch("setShowNav",true);
  },
  mounted(){
    console.log(this.$refs.swipeList1);
    console.log(document.querySelector("#swipeList1>.van-swipe__track--vertical"));

  } 

};
</script>
<style>
.home .van-swipe-item{
  background: #ffffff;
} 
.home .van-swipe-item img{
  border-radius: 10px;
}

</style>
<style scoped>
.token_list{
  display:flex;
}
.citem{
  flex-shrink: 0;
      padding: 15px;
    width: 17%;
    line-height: 25px;
}
.citem>div{
  display:flex;
  justify-content: center;
}
.citem>div:nth-child(2){
  font-size:0.28rem;
}
.citem>div:nth-child(2)>span:nth-child(1){
  font-weight:bold;
}
.citem>div:nth-child(2)>span:nth-child(2){
  color:#b0b0b0;
}
.citem>div:nth-child(3){
   font-weight:bold;
   font-size:0.38rem;
}
 .token_name{
  
 }
.mission{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  
}
.mission>div{
  padding-left:10px;
}
.invite img{
  width:100%;
}
.task_info{
  display:flex;
  flex-direction: column;
  align-items: stretch;
  justify-content:space-around;
}
.task_info>div:nth-child(1){
  color:#F9034F;
 font-weight: 600;
  font-size:14px;

}
.task_info>div:nth-child(2){
  color:#F9034F;
   font-size:12px;
 font-weight: 400;
 color: #999999;

}
.tbcontent{
  padding:20px 0px;
}
.tbItem{
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: stretch;
  background: #ffffff;
}
.tbItem .user_info{
  display: flex;
  flex-direction: column;
  margin-left:10px;
  justify-content:space-around;
  flex-grow:1;
}
.tbItem .user_name{
  display:flex;
  flex-direction: row;
 justify-content: space-between;
 font-size:0.35rem;
}
.tbItem .user_msg{
  display:flex;
  justify-content: flex-start;
  font-size:0.32rem;
}
.tbItem .user_name>div{
  
  display: flex;
  align-items: flex-start;
  
}
.tbactive{
  color:#000000 !important;
  border-bottom:3px solid red !important;
}
.messgeshow{
  margin:10px 14px;
}
.tabtitle{
  display:flex;
  flex-direction: row;
}
.tabtitle>div{
  font-size:15px;
  font-weight: bold;
  margin:0px 20px 0px  0px;
  color:#cccccc;
  border-bottom:3px solid #ffffff;
  
}
.title{
 display:flex;
}
.title>div{
  width:105px;
  height:30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left:3px solid red;
  font-weight: bold;
  font-size:13px;
}
.user_img >img{
  width:46px;
  border-radius: 30px;
}
.item{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:10px 14px 10px; 
  
}
.item>div{
  
  height:2rem;
  flex-basis: 50%;
}
.item_detail{
  display:flex;
  flex-direction: row; 
  justify-content: space-between;
 
  border-radius: 4px;
  color:#ffffff;
  margin-bottom:10px;
}
.item_detail_wrap_odd{
  margin-left:0px;
}
.item_detail_wrap_even{
    margin-left:10px;
}
.invite{
  margin:0.45rem 14px 0.22px; 
  border-radius:10px;
}
.missionwrap img{
  margin-right:10px;
}
.info{
  color:#000000;
  font-size:0.45rem;
}
</style>
<style lang="less">
.home .van-tab{
  flex:0;
}
.home{
  display: -webkit-flex;
  flex-direction: column;
  overflow-x: hidden;
}
.headerPart{
  height:6.7rem;
  width:100%;
  background: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  justify-content: flex-end;
  flex-direction: column;
}
 .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
  }
   .swipeWrap1{
     border-radius: 10px;
     overflow: hidden;
     width:100%;
   }
    
.swipeWrap{
  overflow:hidden;
   display: flex;
   justify-content: center;
}
.info{
  height:1.5rem;
  display:flex;
  align-items: center;
  justify-content: center;
}


.item_detail_wrap{
   display:flex;
  flex-direction: row; 
  justify-content: space-between;
  background: #131b2f;
  border-radius: 4px;
  color:#ffffff;
  flex-grow: 1;
  align-items: center;
  

}
.item_detail_wrap>div{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:0.37rem;
}
.item_detail_wrap>img{
  width:1.38rem;
  height:1.38rem;
}
.tabcontent{
  background: #ffffff;
}

</style>
